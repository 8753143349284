<template>
  <q-page class="flex flex-top">
    <div class="row full-width items-stretch">
      <div class="col bg-agora-light-4">
        <div class="row fit justify-end content-center items-center q-pr-xl">
          <q-img src="/statics/gan-small.png" style="height: 60px; max-width: 60px" />
          <h1 class="title q-pl-lg q-pr-xl">{{$t('global.site-name')}}</h1>
        </div>
      </div>

      <div class="col">
        <div class="row fit content-center q-pa-xl">
          <div class="col col-8">
            <div class="text-overline text-agora">{{$t('auth.lost_password')}}</div>
            <p>{{$t('auth.lost_password_instructions')}}</p>
            <form class="q-gutter-sm"
              @submit.prevent.stop="recoverPassword"
              @reset.prevent.stop="reset"
              :disabled="pending"
            >
              <q-input v-model="formData.email" ref="email" @keyup.native="$refs['email'].resetValidation()"
                :label="$t('auth.email')" color="agora"
                :rules="[val => !!val || $t('form.required_field')]"
              >
                <template v-slot:append><q-icon name="email" /></template>
              </q-input>

              <q-btn color="agora" class="col full-width" size="sm" unelevated type="submit">{{$t('auth.recover_password')}}</q-btn>
              <q-btn color="agora" class="col full-width" size="sm" flat to="/login">{{$t('auth.back_to_login_page')}}</q-btn>
            </form>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'LostPasswordPage',

  data () {
    return {
      formData: { email: '' },
      pending: false
    }
  },

  methods: {
    recoverPassword () {
      this.$refs['email'].validate()

      if (this.$refs['email'].hasError) {
        this.$q.notify({
          message: this.$t('form.missing_fields'),
          color: 'negative'
        })
      } else {
        this.pending = true
        this.$axios.post('/api/auth/password/email', this.formData)
          .then(() => {
            this.$q.notify({
              message: this.$t('auth.recovery_email_sent'),
              color: 'positive'
            })
            // this.$router.push('/')
          }).catch(error => this.$q.notify({
            message: this.$t(error),
            color: 'negative'
          })).finally(() => { this.pending = false })
      }
    },

    reset () {
      this.formData = {
        email: ''
      }
    }
  }
}
</script>
